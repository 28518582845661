module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"johnmantas","defaultLang":"en-us","pages":[{"type":"Project","match":"/:uid","path":"/","component":"/opt/render/project/src/src/templates/Project.js"}],"sharpKeys":[{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"en","name":"John Mantas - Web Developer","short_name":"John Mantas","description":"Personal portfolio and home of John Mantas projects for websites, web apps and games.","background_color":"#f7f0eb","theme_color":"#a2466c","display":"minimal-ui","icon":"/opt/render/project/src/src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
